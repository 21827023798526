/* eslint-disable max-len */
export const CODE_VERIFICATION = '/e-commerce/codeVerification';
export const GET_PLAN = '/e-commerce/getPlan';
export const PAYMENT_DETAILS = '/e-commerce/paymentDetails';
export const LOGIN = '/e-commerce/login';
export const DELETE_ACCOUNT = '/e-commerce/account/'; // :userid
export const GET_GROUP = '/e-commerce/group/'; // :packageId

export const GET_CREDIT_CARD = '/e-commerce/cards/'; // :userId
export const ADD_CREDIT_CARD = '/e-commerce/card/'; // :userId            :cardId OR :userId

export const TOGGLE_SUBSCRIPTION = '/e-commerce/plan/'; // :userId
export const GET_SUBSCRIPTION = '/e-commerce/currentSubscription';
export const UNSUBSCRIBE = '/e-commerce/unsubscribe';
export const RE_ACTIVATE_SUBSCRIPTION = '/e-commerce/activeSub';

export const VERIFY_EMAIL = '/e-commerce/verifyEmail';
export const UPDATE_PROFILE = '/e-commerce/updateProfile';

export const SHOW_PAYMENT_STATUS = '/e-commerce/org/updateAccount';

export const PLAYSTORE_LINK =
  'https://play.google.com/store/apps/details?id=com.policepriority.incidentcode.qa';
export const APPSTORE_LINK =
  'https://apps.apple.com/us/app/incident-go/id1454003350';
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
