export const getItemFromStorage = (key) => {
  const item = window.localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

export const setItemInStorage = (name, data) => {
  window.localStorage.setItem(name, JSON.stringify(data));
};

export const removeItemFromStorage = (name) => {
  window.localStorage.removeItem(name);
};

export const formatDate = (date) => {
  return `${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(
    -2,
  )}-${date.getFullYear()}`;
};

export const toSentenceCase = (text) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};
