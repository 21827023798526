import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import { AuthProvider, useAuth } from '../hooks/useAuth';
import SpinnerComponent from '../components/spinner/SpinnerComponent';
import { BASE_URL } from '../network/Api';

const AuthenticatedApp = React.lazy(() => import('./AuthorisedApp'));
const UnauthenticatedApp = React.lazy(() => import('./UnAuthorisedApp'));

const App = () => {
  useEffect(() => {
    axios.defaults.baseURL = BASE_URL;
  }, []);
  const user = useAuth();
  return (
    <Suspense fallback={<SpinnerComponent />}>
      {user?.isloggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </Suspense>
  );
};

const AppWithProvider = () => {
  return (
    <Router>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Router>
  );
};

export default AppWithProvider;
