import React from 'react';
import { Spinner } from 'reactstrap';

const SpinnerComponent = () => {
  return (
    <div>
      <Spinner
        color="primary"
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '5rem',
          height: '5rem',
          marginLeft: '-2.5rem',
          marginTop: '-2.5rem',
        }}
      />
    </div>
  );
};

export default SpinnerComponent;
